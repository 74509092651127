<template>
  <!-- FAQ Area Start -->
  <section id="faq" class="faq-area">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <div class="section-title-area text-center">
            <SectionHeading2 :title="title" :titleInner="titleInner" />
            <br />
            <SectionSubHeading :subTitle="subTitle" />
          </div>
        </div>
      </div>

      <div class="row d-flex justify-content-center">
        <div class="faq-wrapper col-12 col-md-10">
          <div class="faq-all-box">
            <div class="panel-group" id="accordion6" role="tablist">
              <div class="col-6 col-sm-12 col-md-6">
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Assurance</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          External Audit
                        <li>
                          Internal Audit
                        <li>
                          Fixed Asset Management
                        </li>
                        <li>
                          Stock Counts
                        </li>
                        <li>
                          In-Country Valuation
                        </li>
                        <li>
                          Agreed Upon Procedures
                        </li>
                        <li>
                          Economic Substance Reporting
                        </li>
                        <br>
                        <br>
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>

                <!-- Single Features Box-->

                <!-- Single Features Box-->
                <!-- <div class="col-12 col-sm-12 col-md-6"> -->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a>Business Consulting Services</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          <strong
                            >Performance Review (CSF and KPIs)
                            Evaluation:</strong
                          >
                          Assessing critical success factors and key performance
                          indicators.
                        </li>
                        <li>
                          <strong>Debt Management Strategy Solutions</strong>
                        </li>
                        <li><strong>Business Valuation</strong></li>
                        <li><strong>Budgeting and Forecasting</strong></li>
                        <li>
                          <strong
                            >Financial Planning, Analysis, and Modeling:</strong
                          >
                          Strategic financial planning and sophisticated
                          financial modeling.
                        </li>
                        <li>
                          <strong
                            >Market Research and Feasibility Studies</strong
                          >
                        </li>
                        <li>
                          <strong>Startup Consulting and Support:</strong>
                          Guidance and support for startup ventures.
                        </li>
                        <li><strong>Business Restructuring</strong></li>
                        <li><strong>Cashflow Mismatch Solutions</strong></li>
                        <li>
                          <strong>Loan Requests and Lending Advice</strong>
                        </li>
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Taxation</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          <strong>Corporate Tax:</strong> Comprehensive
                          corporate tax services.
                        </li>
                        <li>
                          <strong>Value Added Tax (VAT):</strong> VAT compliance
                          and advisory services.
                        </li>
                        <li>
                          <strong>Cross Border Investments:</strong> Tax
                          planning and compliance for cross-border investments.
                        </li>
                        <br />
                        <br />
                        <br />
                        <br />
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>

                <!-- Single Features Box-->

                <!-- Single Features Box-->
                <!-- <div class="col-12 col-sm-12 col-md-6"> -->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a>Fractional CFO</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>Strategic Financial Planning and Analysis</li>
                        <li>Cash Flow Management</li>
                        <li>Budgeting and Cost Management</li>
                        <li>Financial Reporting and Analysis</li>
                        <li>Capital Structuring and Fundraising</li>
                        <li>Working Capital Management</li>
                        <li>Risk Management and Compliance</li>
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <!-- Single Features Box-->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>AML - CFT Advisory</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>Executive Coaching and Mentoring</li>
                        <li>Strategic Planning</li>
                        <li>Performance Evaluation</li>
                        <li>Leadership Development</li>
                        <li>Custom Solutions</li>
                        <li>AML-CFT Program Development</li>
                        <li>Risk-Based Assessment and Management</li>
                        <li>Governance, Risk, and Compliance (GRC)</li>
                        <li>
                          Compliance Training and Awareness, CDD / EDD and
                          regulatory Support
                        </li>
                        <li>Technology solutions</li>
                        <li>AML - CFT Audit</li>
                        <li>Cross-Border Compliance</li>
                        
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <!-- Single Features Box-->


                
              </div>

              <div class="col-6 col-sm-12 col-md-6">
                <!-- Single Features Box-->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Accounting and Bookkeeping</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          Accounting and Bookkeeping
                          
                        </li>
                        <li>
                          Backlog Accounting
                        </li> -->
                        <!-- <li>
                          <strong>Software Setup:</strong> Implementing and
                          configuring accounting software.
                        </li> -->
                        <!-- <li>
                          Outsourcing
                        </li> -->
                        <!-- <li> -->
                          <!-- Preparation and Review of Financial
                            Statements -->
                        <!-- </li> -->
                        <!-- <li>Payroll Services</li> -->
                        <!-- <li> -->
                          <!-- Bank Reconciliations -->
                        <!-- </li> -->
                        <!-- <li>Financial Reporting</li> -->
                        <!-- <li>Expense Tracking and Analysis</li> -->
                        <!-- <li>Custom Financial Analysis</li> -->
                      <!-- </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <!-- Single Features Box-->

                <!-- Single Features Box-->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Financial advisory</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          <strong
                            >Feasibility / Forecast / Estimation / Modeling /
                            Projections:</strong
                          >
                          Comprehensive financial analysis and projections.
                        </li>
                        <li>
                          <strong>WFM / LBOs / DCF:</strong> Financial modeling
                          for various business scenarios.
                        </li>
                        <li>
                          <strong>Business Valuation:</strong> Accurate business
                          valuation services.
                        </li>
                        <li>
                          <strong>Pricing Architecture Modeling:</strong>
                          Developing pricing models and strategies.
                        </li>
                        <li>
                          <strong>Sensitivity Modeling:</strong> Analyzing the
                          impact of different scenarios on business performance.
                        </li>
                        <li>
                          <strong>Cash Burn Analysis:</strong> Evaluating cash
                          burn rates and optimizing cash flow.
                        </li>
                        <li>
                          <strong>Waterfall Modelling for Investors</strong>
                        </li>
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <!-- Single Features Box-->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Risk Advisory</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>Risk Assessment and Management</li>
                        <li>Enterprise Risk Management (ERM)</li>
                        <li>Operational Risk Management</li>
                        <li>Regulatory Compliance</li>
                        <li>IT Risk Management</li>
                        <li>Fraud Risk Management</li>
                        <li>Business Continuity Planning</li>
                        <li>Crisis Management</li>
                        <li>Internal Controls</li>
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
                <!-- Single Features Box-->

                <!-- Single Features Box-->
                <b-card no-body class="panel">
                  <b-card-header
                    header-tag="header"
                    class="panel-heading"
                    role="tab"
                  >
                    <h4 class="panel-title">
                      <a block>Restructuring Advisory</a>
                    </h4>
                  </b-card-header>
                  <b-card-body>
                    <b-card-text>
                      <!-- <ul>
                        <li>
                          Mergers & Acquisitions: Comprehensive support for M&A
                          activities.
                        </li>
                        <li>
                          Business Valuation: Accurate valuation for
                          restructuring purposes.
                        </li>
                        <li>
                          Business Process Reengineering: Redesigning business
                          processes for optimal performance.
                        </li>
                        <li>
                          Business Process Optimization: Continuous improvement
                          of business processes.
                        </li>
                        <li>Business Planning</li>
                        <li>Due Diligence</li>
                        <li>Fit-and-Proper Valuation</li>
                        <li>Dispute Resolution</li>
                        <li>Debt Optimization</li>
                        <li>Collateral Rationalization</li>
                        <li>Management Buyouts (MBOs)</li>
                        <li>
                          Succession Planning: Planning for seamless business
                          succession
                        </li>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        <br>
                        
                      </ul> -->
                    </b-card-text>
                  </b-card-body>
                </b-card>
              </div>
            </div>
          </div>
        </div>


        
        <!-- </div> -->
        <!-- FAQ List Left Side End -->
      </div>
      <div class="row">
        <div class="col-12">
          <div class="section-title-area text-center">
            <br />
            <br />
            <SectionSubHeading1 :subTitle="bottomPart" />
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- FAQ Area End -->
</template>

<script>
import SectionHeading2 from "@/components/default/SectionHeading2";
import SectionSubHeading from "@/components/default/SectionSubHeading";
import SectionSubHeading1 from "@/components/default/SectionSubHeading";

export default {
  name: "PopularQuestions",

  components: {
    SectionHeading2,
    SectionSubHeading,
    SectionSubHeading1,
  },

  data() {
    return {
      title: "Services & Solutions",
      titleInner: "Our",
      subTitle:
        "We offer a comprehensive suite of financial solutions designed to empower individuals and businesses in their financial endeavors. Our services are tailored to address a wide spectrum of financial needs, including",
      bottomPart:
        "Our commitment to your financial well-being drives us to deliver strategic, customized solutions that transform financial challenges into opportunities for growth and prosperity",
    };
  },
};
</script>

<style scoped>
.faq-area .card-header.panel-heading {
  padding: 0;
}
.faq-area #accordion6 .panel-title a {
  min-height: auto;
}
.faq-area #accordion6 .card-body {
  border-bottom: 1px solid transparent;
  padding-left: 0;
}
.faq-area .card-header {
  border-bottom: 1px solid transparent;
}
</style>